import api from '../api/api'


const blog = {
    loadPosts(page, dataPerPage, search) {
        return new Promise((resolve, reject) => {
          api.get(`/blog/posts/?page=${page}&page_size=${dataPerPage}&query=${search}`, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    loadPost(id) {
        return new Promise((resolve, reject) => {
          api.get(`/blog/posts/${id}/`, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    loadCategories(page, dataPerPage, search) {
        return new Promise((resolve, reject) => {
          api.get(`/blog/categories/?page=${page}&page_size=${dataPerPage}&query=${search}`, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    loadTags(page, dataPerPage, search) {
        return new Promise((resolve, reject) => {
          api.get(`/blog/tags/?page=${page}&page_size=${dataPerPage}&query=${search}`, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    loadComments(page, dataPerPage, search) {
        return new Promise((resolve, reject) => {
          api.get(`/blog/comments/?page=${page}&page_size=${dataPerPage}&query=${search}`, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    loadSubscribers(page, dataPerPage, search) {
        return new Promise((resolve, reject) => {
          api.get(`/blog/subscribers/?page=${page}&page_size=${dataPerPage}&query=${search}`, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
}

export default blog;