<template>
  <div>
    <!-- start preloader -->
<!--    <div class="preloader" id="preloader"></div>-->
    <!-- end preloader -->

    <!-- Scroll To Top Start-->
    <a href="javascript:void(0)" class="scrollToTop"><i class="fas fa-angle-double-up"></i></a>
    <!-- Scroll To Top End -->

    <!-- header-section start -->
    <header class="header-section header-fixed">
        <div class="overlay">
            <div class="container">
                <div class="row d-flex header-area">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <a class="navbar-brand" href="index.html">
                            <img src="../../public/assets/images/logo.png" style="max-width: 80px;" class="logo" alt="logo">
                        </a>
                        <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbar-content">
                            <i class="fas fa-bars"></i>
                        </button>
                        <div class="collapse navbar-collapse justify-content-end" id="navbar-content">
                            <ul class="navbar-nav mr-auto mb-2 mb-lg-0">

                                <li class="nav-item main-navbar">
                                    <router-link class="nav-link" to="/">Home</router-link>
                                </li>

                                <li class="nav-item main-navbar" @click="scrollToSection('our_values')">
                                    <a class="nav-link" href="javascript:void(0)">About</a>
                                </li>

                                <li class="nav-item main-navbar" @click="scrollToSection('our_services')">
                                    <a class="nav-link" href="javascript:void(0)">Services</a>
                                </li>

                                <li class="nav-item main-navbar" @click="scrollToSection('our_team')">
                                    <a class="nav-link" href="javascript:void(0)">Our Team</a>
                                </li>

<!--                                <li class="nav-item main-navbar" @click="scrollToSection('contact_us')">-->
<!--                                    <a class="nav-link" href="javascript:void(0)">Contact Us</a>-->
<!--                                </li>-->

<!--                                <li class="nav-item main-navbar">-->
<!--                                    <router-link :to="'/blog'" class="nav-link" href="javascript:void(0)">Blog</router-link>-->
<!--                                </li>-->

                                <li class="nav-item main-navbar">
                                  <router-link class="nav-link" :to="'/login'">Login</router-link>
                                </li>

                            </ul>

                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    <!-- header-section end -->

    <!-- Banner Section start -->
    <section class="banner-section index">
        <div class="overlay">
            <div class="shape-area">
<!--                <img src="assets/images/banner-box.png" class="obj-1" alt="image">-->
<!--                <img src="assets/images/banner-human.png" class="obj-2" alt="image">-->
<!--                <img src="assets/images/banner-rocket.png" class="obj-3" alt="image">-->
<!--                <img src="assets/images/banner-clock.png" class="obj-4" alt="image">-->
            </div>

            <div class="banner-content" style="background-image: url('https://vasiliafrica.com/wp-content/uploads/2023/10/Top-15-Money-Market-Funds-in-Kenya-September-2023.jpg')">
                <div class="container wow fadeInUp">
                    <div class="content-shape">
                        <img src="assets/images/banner-wallet.png" class="obj-1" alt="image">
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-5 col-md-6">
                            <div class="main-content">
                                <div class="top-area section-text">
                                    <h5 class="sub-title">Access Deep Liquidity in Africa</h5>
                                    <h1 class="title">Better than the Bank Wire</h1>
                                    <p class="xlr">DurraFX offers reliable, real-time access to liquidity for International Money Transfer
                                        Operators (IMTOs), collection merchants, and FX brokers across Africa. Our solutions are built for speed,
                                        transparency, and efficiency, providing unparalleled access to local currencies and cross-border settlement
                                        faster than traditional banks.</p>
                                </div>
                                <div class="bottom-area d-xxl-flex">
                                    <a href="sign-up.html" class="cmn-btn">Open a Free Account</a>
                                    <a class="cmn-btn active mfp-iframe popupvideo" href="">See How it Works</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-5 col-md-6">

                            <div class="send-money">
                                <form action="#">
                                    <div class="currency-area">
                                        <div class="left-side">
                                            <span class="mdr">You send</span>
                                            <input type="text" class="xlr" placeholder="Enter Amount" v-model="form.currencyToSend">
                                        </div>
                                        <div class="right-side">
                                              <v-select :options="currencies" v-model="form.selectedCurrency" :multiple="false" required  style="width:160px;"
                                                  :reduce="(option) => option"
                                                  :getOptionLabel="(option) => `${option.initials}`"
                                                  @search="(search, loading)=>{
                                                  loading(true)
                                                  getCurrencies(search)
                                                  loading(false)
                                                  }">
                                              </v-select>
                                        </div>
                                    </div>
                                    <div class="calculation">
                                        <div class="head-area">
                                            <img src="../../public/assets/dashboard/images/icon/conversion.png" alt="image">
                                            <span class="mdr highlight">Show calculation</span>
                                        </div>
                                        <div class="calculation-details">
<!--                                            <div class="single-area d-flex align-items-center">-->
<!--                                                <div class="left-area">-->
<!--                                                    <i class="fas fa-minus"></i>-->
<!--                                                    <span class="mdr" v-if="form.selectedCurrency.initials === 'USD'">-->
<!--                                                      {{ $filters.round((form.currencyToSend / form.toCurrency.conversion_factor) ) }} USD-->
<!--                                                    </span>-->
<!--                                                    <span class="mdr" v-else>-->
<!--                                                      {{ $filters.round((form.currencyToSend / form.selectedCurrency.conversion_factor) * form.fees/100) }} USD-->
<!--                                                    </span>-->
<!--                                                </div>-->
<!--                                                <div class="right-area">-->
<!--                                                    <span class="mdr">Our fee</span>-->
<!--                                                </div>-->
<!--                                            </div>-->
                                            <div class="single-area d-flex align-items-center">
                                                <div class="left-area">
<!--                                                    <i class="fas fa-equals"></i>-->
                                                    <span class="mdr">{{ form.currencyToSend }} {{ form.selectedCurrency.initials }}</span>
                                                </div>
                                                <div class="right-area">
                                                    <span class="mdr">We’ll Convert</span>
                                                </div>
                                            </div>
                                            <div class="single-area d-flex align-items-center">
                                                <div class="left-area">
<!--                                                    <i class="fas fa-times"></i>-->
                                                    <span class="mdr" v-if="form.selectedCurrency.initials === 'USD' && form.toCurrency !== {}">
                                                      {{ $filters.round(parseFloat(form.toCurrency.conversion_factor) - (form.toCurrency.conversion_factor*(fees/100))) }}
                                                    </span>
                                                    <span class="mdr" v-else-if="form.selectedCurrency.initials !== 'USD' && form.selectedCurrency !== {}">
                                                      {{ $filters.round((1/parseFloat(form.selectedCurrency.to_usd)) + ((1/form.selectedCurrency.to_usd)*(fees/100))) }}
                                                    </span>
                                                </div>
                                                <div class="right-area">
                                                    <span class="mdr highlight">DurraFX Rate</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="currency-area">
                                        <div class="left-side">
                                            <span class="mdr">Recipient gets</span>
                                            <h5>
                                              <span v-if="form.selectedCurrency.initials === 'USD' && form.toCurrency !== {}">
                                                {{ $filters.round( form.currencyToSend * (parseFloat(form.toCurrency.conversion_factor) - (form.toCurrency.conversion_factor*(fees/100)))) }}
                                              </span>
                                              <span v-else-if="form.selectedCurrency.initials !== 'USD' && form.selectedCurrency !== {}">
                                                {{ $filters.round( form.currencyToSend / (parseFloat(form.selectedCurrency.conversion_factor) + (form.selectedCurrency.conversion_factor*(fees/100)))) }}
                                              </span>
                                              <span v-else>
                                                0
                                              </span>

                                            </h5>
                                        </div>
                                        <div class="right-side recipient">
                                          <template v-if="form.selectedCurrency.initials !== 'USD'">
                                            <select v-model="form.toCurrency">
                                              <span v-for="currency in currencies" v-bind:key="currency.id">
                                                <option selected v-if="currency.initials ==='USD'" :value="currency">{{ currency.initials }}</option>
                                              </span>
                                            </select>
                                          </template>
                                          <template v-else-if="form.selectedCurrency.initials === 'USD'">
                                            <select v-model="form.toCurrency">
                                              <span v-for="currency in currencies" v-bind:key="currency.id">
                                                <option selected v-if="currency.id !== form.selectedCurrency.id" :value="currency">{{ currency.initials }}</option>
                                              </span>
                                            </select>
                                          </template>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-40">
                                        <a href="javascript:void(0)" class="cmn-btn w-100">Get Started</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="counter-section">
            <div class="container wow fadeInUp">
                <div class="row cus-mar">
                    <div class="col-xl-3 col-md-3 col-sm-6">
                        <div class="single-area d-flex align-items-center justify-content-center">
                            <div class="text-area text-center">
                                <h2><span class="counter">10</span><span>+</span></h2>
                                <p>Supported Currencies</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-3 col-sm-6">
                        <div class="single-area d-flex align-items-center justify-content-center">
                            <div class="text-area text-center">
                                <h2><span class="counter">10</span><span>+</span></h2>
                                <p>Available Countries</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-3 col-sm-6">
                        <div class="single-area d-flex align-items-center justify-content-center">
                            <div class="text-area text-center">
                                <h2><span class="counter">7</span><span>+</span></h2>
                                <p>Payment Methods</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-3 col-sm-6">
                        <div class="single-area d-flex align-items-center justify-content-center">
                            <div class="text-area text-center">
                                <h2><span class="counter">7</span><span>/</span><span class="counter">24</span><span>+</span></h2>
                                <p>Support Team</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Banner Section end -->

    <br>
    <br>
    <!-- Our Values start -->
    <section class="our-values" id="our_values">
        <div class="overlay pt-20 pb-120">
            <div class="container wow fadeInUp">
                <div class="row align-items-center justify-content-center">
                    <div class="col-xl-12">
                        <div class="section-header text-center">
<!--                            <h5 class="sub-title">Our values</h5>-->
                            <h2 class="title">Our Story</h2>
                          <p>DurraFX is a payments company that specializes in delivering crypto-powered solutions
                              designed to simplify cross-border payments and FX services for businesses across Africa
                              and beyond. With operations in Kenya, Tanzania, Rwanda, Uganda, the USA, and seven Southeast
                              Asian countries, we are committed to connecting the world to African markets, offering
                              deep liquidity, same-day settlements, and cutting-edge API solutions to our clients.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row cus-mar align-items-center">
                    <div class="col-lg-6">
                        <div class="single-box d-flex">
                            <div class="img-box">
                                <img src="../../public/assets/images/values-icon-3.png" alt="icon">
                            </div>
                            <div class="text-box">
                                <h4>Our Mission</h4>
                                <p>
                                  To revolutionize how businesses in Africa access liquidity and facilitate payments,
                                  making financial transactions seamless and cost-effective for our clients.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="single-box d-flex">
                            <div class="img-box">
                                <img src="../../public/assets/images/values-icon-3.png" alt="icon">
                            </div>
                            <div class="text-box">
                                <h4>Our Vision</h4>
                                <p>
                                  A future where businesses, regardless of size, can effortlessly transact globally,
                                  with Africa at the heart of cross-border payment networks.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <!-- Our Values end -->

    <!-- Global Payment start -->
<!--    <section class="global-payment">-->
<!--        <div class="overlay pt-120 pb-120">-->
<!--            <div class="container wow fadeInUp">-->
<!--                <div class="row justify-content-center">-->
<!--                    <div class="col-lg-6">-->
<!--                        <div class="section-header text-center">-->
<!--                            <h5 class="sub-title">Send money in a heartbeat</h5>-->
<!--                            <h2 class="title">The World At Your Fingertips</h2>-->
<!--                            <p>Sign up to start saving on international money transfers and currency exchange.</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="row">-->
<!--                    <div class="col-xxl-6 col-xl-5 order-xl-0 order-1">-->
<!--                        <div class="image-area d-rtl left-side">-->
<!--                            <img src="../../public/assets/images/global-payment-img.png" alt="images" class="max-un">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-xxl-6 col-xl-7">-->
<!--                        <div class="row cus-mar">-->
<!--                            <div class="col-sm-6 col-6">-->
<!--                                <div class="single-item">-->
<!--                                    <img src="../../public/assets/images/global-payment-icon-1.png" alt="icon">-->
<!--                                    <h5>Peace of Mind</h5>-->
<!--                                    <p>You dont have to struggle following how your money is handled. We do it for you.</p>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-sm-6 col-6">-->
<!--                                <div class="single-item">-->
<!--                                    <img src="../../public/assets/images/global-payment-icon-2.png" alt="icon">-->
<!--                                    <h5>Business-Ready</h5>-->
<!--                                    <p>Make payments and receive money from your clients any time.</p>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-sm-6 col-6">-->
<!--                                <div class="single-item">-->
<!--                                    <img src="../../public/assets/images/global-payment-icon-3.png" alt="icon">-->
<!--                                    <h5>100% Transparent</h5>-->
<!--                                    <p>Our fee and costs are available to see what you will be charged without any hidden costs.</p>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-sm-6 col-6">-->
<!--                                <div class="single-item">-->
<!--                                    <img src="../../public/assets/images/global-payment-icon-4.png" alt="icon">-->
<!--                                    <h5>International Network</h5>-->
<!--                                    <p>With our partiners in amny countries, be assured to to tranasct with clients all over the world in our platform.</p>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->
    <!-- Global Payment end -->

    <!-- Our Solutions start -->
    <section class="our-solutions" id="our_services">
        <div class="overlay pb-120">
            <div class="container wow fadeInUp">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="section-header dark-sec text-center">
<!--                            <h5 class="sub-title">High speeds. Low fees. No hassle.</h5>-->
                          <br>
                            <h2 style="color: #f2f3f9" class="title">All Your Payments In One Place</h2>
                            <p style="color: #f2f3f9">Get used to low fees and great exchange rates on international money transfers.Expand your business worldwide</p>
                        </div>
                    </div>
                </div>
                <div class="row cus-mar">
                    <div class="col-xl-3 col-6">
                        <div class="single-item">
                            <img src="../../public/assets/images/our-solutions-icon-1.png" alt="icon">
                            <h5>Liquidity Provision for IMTOs</h5>
                            <p>
                              We provide reliable local liquidity solutions tailored for International Money Transfer Operators, ensuring seamless and efficient transactions.
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="single-item">
                            <img src="../../public/assets/images/our-solutions-icon-2.png" alt="icon">
                            <h5>Collections</h5>
                            <p>
                              We enable businesses to collect payments from merchants and customers worldwide, offering solutions tailored to meet the needs of international organizations.
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="single-item">
                            <img src="../../public/assets/images/our-solutions-icon-3.png" alt="icon">
                            <h5>Conversions</h5>
                            <p>
                              We offer competitive rates and a wide range of currencies for seamless and efficient exchanges in major African markets, with guaranteed same-day settlements.
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="single-item">
                            <img src="../../public/assets/images/our-solutions-icon-4.png" alt="icon">
                            <h5>Global Account</h5>
                            <p>Get a single account for all your global payments, offering convenience and security across borders, with seamless international transfers.</p>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Our Solutions end -->

    <!-- How it Works start -->
    <section class="how-it-works">
        <div class="overlay pb-120">
            <div class="container wow fadeInUp">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="section-header text-center">
<!--                            <h5 class="sub-title">How it works?</h5>-->
                            <h2 class="title">Just few steps to start</h2>
                            <p>It's easier than you think. Follow 3 simple easy steps</p>
                        </div>
                    </div>
                </div>
                <div class="row cus-mar">
                    <div class="col-xl-3 col-sm-6 col-6">
                        <div class="single-item first text-center">
                            <img src="../../public/assets/images/how-works-icon-1.png" alt="icon">
                            <h5>Register for free</h5>
                            <p>Simply sign up online for free and verify your identity</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-6">
                        <div class="single-item second text-center">
                            <img src="../../public/assets/images/how-works-icon-2.png" alt="icon">
                            <h5>Set up your transfer</h5>
                            <p>Add a recipient's details and choose which currency ...</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-6">
                        <div class="single-item first text-center">
                            <img src="../../public/assets/images/how-works-icon-3.png" alt="icon">
                            <h5>Make your payment</h5>
                            <p>Send us your funds with a bank transfer and we'll notify..</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-6">
                        <div class="single-item text-center">
                            <img src="../../public/assets/images/how-works-icon-4.png" alt="icon">
                            <h5>You're all done!</h5>
                            <p>We inform you when the money has been sent and can also ...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- How it Works end -->

    <!-- App Download start -->
    <section class="app-download">
        <div class="overlay ">
            <div class="container wow fadeInUp">
              <div class="row justify-content-center">
                  <div class="col-lg-12">
                      <div class="section-header text-center">
<!--                          <h5 class="sub-title">API Integration</h5>-->
                          <h2 class="title">Seamless API Access to Liquidity:</h2>
                          <p>Our advanced API solutions allow businesses to tap directly into DurraFX’s liquidity pools.
                            Whether you are an IMTO, FX broker, or collection merchant, our API provides real-time access
                            to African currencies, ensuring faster transactions and reducing the need for third-party
                            providers.
                          </p>
                      </div>
                  </div>
              </div>

                <div class="row justify-content-between align-items-center">
                    <div class="col-lg-8 align-items-center">
                        <div class="section-area">
                            <h5 class="sub-title">Key Features of Our API:</h5>
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <ul class="features">
                              <li>
                                  <img src="../../public/assets/images/check.png" alt="icon">
                                  Login with fingerprint or Face ID.
                              </li>
                              <li>
                                  <img src="../../public/assets/images/check.png" alt="icon">
                                  Simple few Taps to send money
                              </li>
                            </ul>
                          </div>

                          <div class="col-sm-6">
                            <ul class="features">
                              <li>
                                  <img src="../../public/assets/images/check.png" alt="icon">
                                  View transaction history.
                              </li>
                              <li>
                                  <img src="../../public/assets/images/check.png" alt="icon">
                                  Get instant App notifications.
                              </li>
                            </ul>
                          </div>
                        </div>

                      <br>
                      <div class="section-area">
                        <h5 class="sub-title">Explore Our API Today</h5>
                        <p>Streamline your operations with DurraFX’s API solution. Request API Documentation.</p>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- App Download end -->

    <br>
    <br>
    <!-- Our Team start -->
    <section class="our-team" id="our_team">
        <div class="overlay pb-120">
            <div class="container wow fadeInUp">
                <div class="row align-items-center justify-content-center">
                    <div class="col-xl-6">
                        <div class="section-header text-center">
<!--                            <h5 class="sub-title">Our team</h5>-->
                            <h2 class="title">The amazing team behind our company</h2>

                            <p>We are a team of entrepreneurs, payment specialists and blockchain enthusiasts.</p>
                        </div>
                    </div>
                </div>
                <div class="row cus-mar align-items-center">
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-box">
                            <div class="img-box">
                                <img style="max-height: 170px !important;" src="../../public/assets/images/jay.jpg" alt="image">
                            </div>
                            <div class="body-area">
                                <h5>Joshua Obaigwa</h5>
                                <p class="designation">CEO & <br> founder</p>
                                <div class="social-link d-flex justify-content-center align-items-center">
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/facebook.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/linkedin.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/instagram.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/twitter.png" alt="icon"></a>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-box">
                            <div class="img-box">
                                <img style="max-height: 170px !important;" src="../../public/assets/images/ronald-passport-photo.jpeg" alt="image">
                            </div>
                            <div class="body-area">
                                <h5>Ronald Mosomi</h5>
                                <p class="designation">CTO & <br> co-founder</p>
                                <div class="social-link d-flex justify-content-center align-items-center">
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/facebook.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/linkedin.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/instagram.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/twitter.png" alt="icon"></a>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-box">
                            <div class="img-box">
                                <img style="max-height: 170px !important;" src="../../public/assets/images/diana-passport.jpg" alt="image">
                            </div>
                            <div class="body-area">
                                <h5>Diana Bitange</h5>
                                <p class="designation">Co-founder & <br>Director Marketing</p>
                                <div class="social-link d-flex justify-content-center align-items-center">
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/facebook.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/linkedin.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/instagram.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/twitter.png" alt="icon"></a>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-box">
                            <div class="img-box">
                                <img style="max-height: 170px !important;" src="../../public/assets/images/IMG_1800-1365x1024-removebg-preview.png" alt="image">
                            </div>
                            <div class="body-area">
                                <h5>Vincent Ogoti</h5>
                                <p class="designation">Director Strategy & <br> Communication</p>
                                <div class="social-link d-flex justify-content-center align-items-center">
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/facebook.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/linkedin.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/instagram.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/twitter.png" alt="icon"></a>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

<!--              <br>-->
<!--                <div class="row cus-mar align-items-center">-->
<!--                    <div class="col-lg-3 col-sm-6">-->
<!--                        <div class="single-box">-->
<!--                            <div class="img-box">-->
<!--                                <img src="assets/images/team-image-1.png" alt="image">-->
<!--                            </div>-->
<!--                            <div class="body-area">-->
<!--                                <h5>Ronald Mosomi</h5>-->
<!--                                <p class="designation">CEO & Co-founder</p>-->
<!--                                <div class="social-link d-flex justify-content-center align-items-center">-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/facebook.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/linkedin.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/instagram.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/twitter.png" alt="icon"></a>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-lg-3 col-sm-6">-->
<!--                        <div class="single-box">-->
<!--                            <div class="img-box">-->
<!--                                <img src="assets/images/team-image-2.png" alt="image">-->
<!--                            </div>-->
<!--                            <div class="body-area">-->
<!--                                <h5>Ronald Mosomi</h5>-->
<!--                                <p class="designation">VP of Product</p>-->
<!--                                <div class="social-link d-flex justify-content-center align-items-center">-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/facebook.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/linkedin.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/instagram.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/twitter.png" alt="icon"></a>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-lg-3 col-sm-6">-->
<!--                        <div class="single-box">-->
<!--                            <div class="img-box">-->
<!--                                <img src="assets/images/team-image-3.png" alt="image">-->
<!--                            </div>-->
<!--                            <div class="body-area">-->
<!--                                <h5>Ronald Mosomi</h5>-->
<!--                                <p class="designation">Head of Marketing</p>-->
<!--                                <div class="social-link d-flex justify-content-center align-items-center">-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/facebook.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/linkedin.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/instagram.png" alt="icon"></a>-->
<!--                                    <a href="javascript:void(0)"><img src="assets/images/icon/twitter.png" alt="icon"></a>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

            </div>
        </div>
    </section>
    <!-- Our Team end -->

    <!-- Location start -->
<!--    <section class="location" id="contact_us">-->
<!--        <div class="overlay pb-120">-->
<!--            <div class="container wow fadeInUp">-->
<!--                <div class="row align-items-center justify-content-center">-->
<!--                    <div class="col-xl-8">-->
<!--                        <div class="section-header text-center">-->
<!--&lt;!&ndash;                            <h5 class="sub-title">Our offices</h5>&ndash;&gt;-->
<!--                            <h2 class="title">Come and visit our offices around the world</h2>-->
<!--                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit diam malesuada nisl enim phasellus condimentum.</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="row align-items-center cus-mar">-->
<!--                    <div class="col-xl-4 col-md-6">-->
<!--                        <div class="single-box">-->
<!--                            <div class="head-area d-flex align-items-center">-->
<!--                                <div class="img-box">-->
<!--                                    <img src="assets/images/icon/location-icon-1.png" alt="image">-->
<!--                                </div>-->
<!--                                <h4>Syokimau</h4>-->
<!--                            </div>-->
<!--                            <div class="email-phone">-->
<!--                                <div class="img-box">-->
<!--                                    <img src="assets/images/icon/email-icon-1.png" alt="icon">-->
<!--                                </div>-->
<!--                                <p><a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="c2b1a3aca4b0a3aca1abb1a1ad82b2a3bbaeabadeca1adaf">[email&#160;protected]</a></p>-->
<!--                            </div>-->
<!--                            <div class="email-phone">-->
<!--                                <div class="img-box">-->
<!--                                    <img src="assets/images/icon/phone-icon-1.png" alt="icon">-->
<!--                                </div>-->
<!--                                <p>254 111 111 111</p>-->
<!--                            </div>-->
<!--                            <div class="bottom-area">-->
<!--                                <a href="javascript:void(0)">-->
<!--                                    View Location-->
<!--                                    <img src="assets/images/icon/arrow-right-4.png" alt="icon">-->
<!--                                </a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-xl-4 col-md-6">-->
<!--                        <div class="single-box">-->
<!--                            <div class="head-area d-flex align-items-center">-->
<!--                                <div class="img-box">-->
<!--                                    <img src="assets/images/icon/location-icon-2.png" alt="image">-->
<!--                                </div>-->
<!--                                <h4>Kilimani</h4>-->
<!--                            </div>-->
<!--                            <div class="email-phone">-->
<!--                                <div class="img-box">-->
<!--                                    <img src="assets/images/icon/email-icon-2.png" alt="icon">-->
<!--                                </div>-->
<!--                                <p><a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="670902101e08150c2717061e0b0e084904080a">[email&#160;protected]</a></p>-->
<!--                            </div>-->
<!--                            <div class="email-phone">-->
<!--                                <div class="img-box">-->
<!--                                    <img src="assets/images/icon/phone-icon-2.png" alt="icon">-->
<!--                                </div>-->
<!--                                <p>254 111 111 111</p>-->
<!--                            </div>-->
<!--                            <div class="bottom-area">-->
<!--                                <a href="javascript:void(0)">-->
<!--                                    View Location-->
<!--                                    <img src="assets/images/icon/arrow-right-4.png" alt="icon">-->
<!--                                </a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-xl-4 col-md-6">-->
<!--                        <div class="single-box">-->
<!--                            <div class="head-area d-flex align-items-center">-->
<!--                                <div class="img-box">-->
<!--                                    <img src="assets/images/icon/location-icon-3.png" alt="image">-->
<!--                                </div>-->
<!--                                <h4>Katani</h4>-->
<!--                            </div>-->
<!--                            <div class="email-phone">-->
<!--                                <div class="img-box">-->
<!--                                    <img src="assets/images/icon/email-icon-3.png" alt="icon">-->
<!--                                </div>-->
<!--                                <p><a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="fc909392989392bc8c9d85909593d29f9391">[email&#160;protected]</a></p>-->
<!--                            </div>-->
<!--                            <div class="email-phone">-->
<!--                                <div class="img-box">-->
<!--                                    <img src="assets/images/icon/phone-icon-3.png" alt="icon">-->
<!--                                </div>-->
<!--                                <p>254 111 111 111</p>-->
<!--                            </div>-->
<!--                            <div class="bottom-area">-->
<!--                                <a href="javascript:void(0)">-->
<!--                                    View Location-->
<!--                                    <img src="assets/images/icon/arrow-right-4.png" alt="icon">-->
<!--                                </a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->
    <!-- Location end -->

    <!-- FAQs In start -->
    <section class="faqs-section">
        <div class="overlay " style="margin-bottom: 20px">
            <div class="container">
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                        <div class="section-header text-center">
<!--                            <h5 class="sub-title">Frequently Asked Questions</h5>-->
                            <h2 class="title">If you got questions we have answers</h2>
                            <p>We have a list of frequently asked questions about us</p>
                        </div>
                    </div>
                </div>
                <div class="row cus-mar">
                    <div class="col-lg-6">
                        <div class="accordion" id="accordionLeft">
                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingLeftOne">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseLeftOne"
                                        aria-expanded="false" aria-controls="collapseLeftOne">
                                        How to send money online?
                                    </button>
                                </h6>
                                <div id="collapseLeftOne" class="accordion-collapse collapse"
                                    aria-labelledby="headingLeftOne" data-bs-parent="#accordionLeft">
                                    <div class="accordion-body">
                                        <p>Get your other partiner's wallet to send the money in seconds.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingLeftTwo">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseLeftTwo"
                                        aria-expanded="false" aria-controls="collapseLeftTwo">
                                        How much are money transfer fees?
                                    </button>
                                </h6>
                                <div id="collapseLeftTwo" class="accordion-collapse collapse"
                                    aria-labelledby="headingLeftTwo" data-bs-parent="#accordionLeft">
                                    <div class="accordion-body">
                                        <p>Up to 1,000,000 usd.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingLeftThree">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseLeftThree"
                                        aria-expanded="false" aria-controls="collapseLeftThree">
                                        What is the fastest way to send money abroad?
                                    </button>
                                </h6>
                                <div id="collapseLeftThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingLeftThree" data-bs-parent="#accordionLeft">
                                    <div class="accordion-body">
                                        <p>By suing the crypto wallet through DurraFx.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingLeftFive">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseLeftFive"
                                        aria-expanded="false" aria-controls="collapseLeftFive">
                                        How does DurraFX protect your money?
                                    </button>
                                </h6>
                                <div id="collapseLeftFive" class="accordion-collapse collapse"
                                    aria-labelledby="headingLeftFive" data-bs-parent="#accordionLeft">
                                    <div class="accordion-body">
                                        <p>We are regulated and have our money sent through secure api.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="accordion" id="accordionRight">

                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingRightTwo">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseRightTwo"
                                        aria-expanded="false" aria-controls="collapseRightTwo">
                                        How much money can I Receive?
                                    </button>
                                </h6>
                                <div id="collapseRightTwo" class="accordion-collapse collapse"
                                    aria-labelledby="headingRightTwo" data-bs-parent="#accordionRight">
                                    <div class="accordion-body">
                                        <p>Your customer can send you any amount as we provide wallets and have good relationships with banks.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingRightThree">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseRightThree"
                                        aria-expanded="false" aria-controls="collapseRightThree">
                                        Which currency can I send?
                                    </button>
                                </h6>
                                <div id="collapseRightThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingRightThree" data-bs-parent="#accordionRight">
                                    <div class="accordion-body">
                                        <p>All cuurencies supported in the world exhanged with either USD or USDT.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingRightFour">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseRightFour"
                                        aria-expanded="false" aria-controls="collapseRightFour">
                                        Cancel transaction
                                    </button>
                                </h6>
                                <div id="collapseRightFour" class="accordion-collapse collapse"
                                    aria-labelledby="headingRightFour" data-bs-parent="#accordionRight">
                                    <div class="accordion-body">
                                        <p>Because of the nature of USDT, make sure you have the correct account before initiating the transaction.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h6 class="accordion-header" id="headingRightFive">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseRightFive"
                                        aria-expanded="false" aria-controls="collapseRightFive">
                                        Can I send multiple payments?
                                    </button>
                                </h6>
                                <div id="collapseRightFive" class="accordion-collapse collapse"
                                    aria-labelledby="headingRightFive" data-bs-parent="#accordionRight">
                                    <div class="accordion-body">
                                        <p>You can send to various wallets and lients at once.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- FAQs In end -->

    <!-- Footer Area Start -->
    <footer class="footer-section">
        <div class="container">

            <div class="footer-area">
                <div class="footer-top">
                    <div class="row align-items-center">
                        <div class="col-sm-6 d-flex justify-content-center justify-content-sm-start">
                            <div class="menu-item">
                                <ul class="footer-link d-flex align-items-center">
                                    <li><a href="about-us.html">About Us</a></li>
                                    <li><a href="help-center.html">Support</a></li>
                                    <li><a href="fees.html">Fees</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="social-link d-flex justify-content-sm-end justify-content-center align-items-center">
<!--                                <a href="javascript:void(0)"><img src="assets/images/icon/facebook.png" alt="icon"></a>-->
<!--                                <a href="javascript:void(0)"><img src="assets/images/icon/linkedin.png" alt="icon"></a>-->
<!--                                <a href="javascript:void(0)"><img src="assets/images/icon/instagram.png" alt="icon"></a>-->
<!--                                <a href="javascript:void(0)"><img src="assets/images/icon/twitter.png" alt="icon"></a>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-md-6 col-sm-8 d-flex justify-content-center justify-content-sm-start order-sm-0 order-1">
                            <div class="copyright text-center text-sm-start">
                                <p> Copyright © 2024 <a href="index.html">DurraFX.</a> All Rights Reserved.</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-4">
                            <div class="menu-item">
                                <ul class="footer-link d-flex justify-content-sm-end justify-content-center align-items-center">
                                    <li><a href="terms-conditions.html">Terms</a></li>
                                    <li><a href="privacy-policy.html">Privacy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer Area End -->

  </div>

</template>

<style scoped>
.header-section {
    margin-left: 0px !important;
    width: 100% !important;
    border-bottom: none;
    box-shadow: 0px 1px 4px grey;
}
.header-section .navbar {
    padding: 8px 15px;
}

.title{
  font-size: 30px;
  margin-bottom: 1px;
}
.xlr {
  font-size: 20px;
}

.pb-120{
  padding-bottom: 40px;
}
</style>

<script setup>

import '../../public/assets/css/bootstrap.min.css'
import '../../public/assets/css/fontawesome.min.css'
import '../../public/assets/css/jquery-ui.css'
// import '../../public/assets/css/nice-select.css'
import '../../public/assets/css/magnific-popup.css'
import '../../public/assets/css/slick.css'
import '../../public/assets/css/arafat-font.css'
import '../../public/assets/css/animate.css'
import '../../public/assets/css/style.css'

import '../../public/assets/js/home_main.js'

</script>

<script>
import core_function from "@/services/core_function";
export default {
  name: "HomePage",
  data() {
    return {
        currencies: [],
        form: {
          "selectedCurrency": {},
          "currencyToSend": 1000,
          "toCurrency": {}
        },
        fees: 1
    }
  },
  watch: {
    'form.selectedCurrency'(){
      if(this.form.selectedCurrency.initials !== 'USD'){
        this.fees = 1
      }else{
        this.fees = 0.5
      }
    }
  },
  components: {

  },
  mounted() {
    this.logOut()
    this.getCurrencies("")
  },
  created() {

  },
  methods: {
    logOut(){
      this.$store.dispatch('Auth/logout')
      this.$store.dispatch('User/logout')
    },
    scrollToSection(sectionId){
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    async getCurrencies(search){
        try{
          let response = await core_function.loadCurrencies(1, 1000, search)
          this.currencies = response.data['results']
          for (const currency of this.currencies) {
            if (currency.initials === "USD") {
              this.form.selectedCurrency = currency;
              console.log(this.selectedCurrency)
              break; // Exit loop once the matching currency is found
            }
          }
        }catch(err){
          console.log(err)
        }
    }
  },

}
</script>