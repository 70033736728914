<template>
  <div>

    <!-- Scroll To Top Start-->
    <a href="javascript:void(0)" class="scrollToTop"><i class="fas fa-angle-double-up"></i></a>
    <!-- Scroll To Top End -->

    <!-- header-section start -->
    <header class="header-section header-fixed">
        <div class="overlay">
            <div class="container">
                <div class="row d-flex header-area">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <a class="navbar-brand" href="index.html">
                            <img src="../../../public/assets/images/logo.png" style="max-width: 80px;" class="logo" alt="logo">
                        </a>
                        <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbar-content">
                            <i class="fas fa-bars"></i>
                        </button>
                        <div class="collapse navbar-collapse justify-content-end" id="navbar-content">
                            <ul class="navbar-nav mr-auto mb-2 mb-lg-0">

                                <li class="nav-item main-navbar">
                                    <router-link class="nav-link" to="/">Home</router-link>
                                </li>

                                <li class="nav-item main-navbar" @click="scrollToSection('our_values')">
                                    <a class="nav-link" href="javascript:void(0)">About</a>
                                </li>

                                <li class="nav-item main-navbar" @click="scrollToSection('our_services')">
                                    <a class="nav-link" href="javascript:void(0)">Services</a>
                                </li>

                                <li class="nav-item main-navbar" @click="scrollToSection('our_team')">
                                    <a class="nav-link" href="javascript:void(0)">Our Team</a>
                                </li>

<!--                                <li class="nav-item main-navbar" @click="scrollToSection('contact_us')">-->
<!--                                    <a class="nav-link" href="javascript:void(0)">Contact Us</a>-->
<!--                                </li>-->

                              <li class="nav-item main-navbar">
                                <router-link :to="'/blog'" class="nav-link" href="javascript:void(0)">Blog</router-link>
                              </li>

                                <li class="nav-item main-navbar">
                                  <router-link class="nav-link" :to="'/login'">Login</router-link>
                                </li>

                            </ul>

                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    <!-- header-section end -->

    <!-- Banner Section start -->
    <section class="banner-section inner-banner career">
        <div class="overlay">
            <div class="shape-area">
                <img src="../../../public/assets/blog/images/banner-blog.png" class="shape-1" alt="image">
            </div>
            <div class="banner-content">
                <div class="container wow fadeInUp">
                    <div class="row align-items-center">
                        <div class="col-lg-8 col-md-7">
                            <div class="main-content">
                                <h1>Blog</h1>
                                <div class="breadcrumb-area">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb d-flex gap-1 align-items-center">
                                            <li class="breadcrumb-item p-0 position-relative"><a href="index.html">Home</a></li>
<!--                                            <li class="breadcrumb-item p-0 position-relative"><a href="javascript:void(0)">Company</a></li>-->
                                            <li class="breadcrumb-item p-0 position-relative active" aria-current="page">Blog</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Banner Section end -->

    <br>
    <!-- Blog start -->
    <section class="blog-section">
        <div class="overlay  pb-120">
            <div class="container wow fadeInUp">
                <div class="row justify-content-center">
                    <div class="col-xl-6">
                        <div class="section-header text-center">
                            <h2 class="title">DurraFx Blog</h2>
                            <p>Follow the blog for product announcements, feature updates, user stories, and technical posts about banking.</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12">
                        <div class="top-area d-flex justify-content-between">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="cmn-btn active" id="all-post-tab" data-bs-toggle="tab" data-bs-target="#all-post" type="button"
                                        role="tab" aria-controls="all-post" aria-selected="true">All</button>
                                </li>

                                <li v-for="category in categories" v-bind:key="category.id" class="nav-item" role="presentation">
                                    <button class="cmn-btn" id="paylio-tab" data-bs-toggle="tab" data-bs-target="#paylio" type="button"
                                        role="tab" aria-controls="paylio" aria-selected="false">{{ category.name }}</button>
                                </li>
                            </ul>
                            <form @submit.prevent="getPosts()">
                                <div class="form-group mb-40 d-flex align-items-center">
                                    <img src="../../../public/assets/blog/images/icon/search-icon-2.png" alt="icon">
                                    <input type="text" placeholder="Search" v-model="search" @keydown.enter="getPosts()">
                                </div>
                            </form>
                        </div>
                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="all-post" role="tabpanel" aria-labelledby="all-post-tab">
                                <div class="row">
                                    <div class="col-lg-12" v-if="dataCount > 0">
                                        <div class="single-post d-flex">
                                            <div class="thumbnail">
                                                <img :src="featuredPost.featured_image" alt="image">
                                            </div>
                                            <div class="blog-text">
                                                <p class="date">By- {{ featuredPost.author }} - {{ featuredPost.created_at }}</p>
                                                <router-link  :to="'/blog/details/'+featuredPost.id"><h4>{{ featuredPost.title }}</h4></router-link>
                                                <p class="blog-content" v-html="$filters.short(featuredPost.content)"></p>
                                                <div class="bottom-area d-flex align-items-center justify-content-between">
                                                    <img src="../../../public/assets/blog/images/blog-user.png" alt="image">
                                                    <div class="btn-area d-flex align-items-center">
                                                        <a href="blog-details.html">Read More<img src="../../../public/assets/blog/images/icon/arrow-right-4.png" alt="icon"></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6" v-for="post in posts" v-bind:key="post.id">
                                        <div class="single-post">
                                            <div class="thumbnail">
                                                <img :src="post.featured_image" alt="image" style="max-width: 416px">
                                            </div>
                                            <div class="blog-text">
                                                <p class="date">By {{ featuredPost.author }} - {{ featuredPost.created_at }}2</p>
                                                <a href="blog-details.html"><h5>{{ featuredPost.title }}</h5></a>
                                            </div>
                                        </div>
                                    </div>

<!--                                    <div class="col-lg-4 col-md-6">-->
<!--                                        <div class="single-post">-->
<!--                                            <div class="thumbnail">-->
<!--                                                <img src="assets/images/blog-image-3.png" alt="image">-->
<!--                                            </div>-->
<!--                                            <div class="blog-text">-->
<!--                                                <p class="date">By Paylio - Feb 7, 2022</p>-->
<!--                                                <a href="blog-details.html"><h5>Various Online Payment Options and Tips to Avoid Fraud..</h5></a>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="col-lg-4 col-md-6">-->
<!--                                        <div class="single-post">-->
<!--                                            <div class="thumbnail">-->
<!--                                                <img src="assets/images/blog-image-4.png" alt="image">-->
<!--                                            </div>-->
<!--                                            <div class="blog-text">-->
<!--                                                <p class="date">By Paylio - Feb 7, 2022</p>-->
<!--                                                <a href="blog-details.html"><h5>Reasons Why You Should Go For Payment solution...</h5></a>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Blog end -->


    <!-- Footer Area Start -->
    <footer class="footer-section">
        <div class="container">

            <div class="footer-area">
                <div class="footer-top">
                    <div class="row align-items-center">
                        <div class="col-sm-6 d-flex justify-content-center justify-content-sm-start">
                            <div class="menu-item">
                                <ul class="footer-link d-flex align-items-center">
                                    <li><a href="about-us.html">About Us</a></li>
                                    <li><a href="help-center.html">Support</a></li>
                                    <li><a href="fees.html">Fees</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="social-link d-flex justify-content-sm-end justify-content-center align-items-center">
<!--                                <a href="javascript:void(0)"><img src="assets/images/icon/facebook.png" alt="icon"></a>-->
<!--                                <a href="javascript:void(0)"><img src="assets/images/icon/linkedin.png" alt="icon"></a>-->
<!--                                <a href="javascript:void(0)"><img src="assets/images/icon/instagram.png" alt="icon"></a>-->
<!--                                <a href="javascript:void(0)"><img src="assets/images/icon/twitter.png" alt="icon"></a>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-md-6 col-sm-8 d-flex justify-content-center justify-content-sm-start order-sm-0 order-1">
                            <div class="copyright text-center text-sm-start">
                                <p> Copyright © 2024 <a href="index.html">DurraFX.</a> All Rights Reserved.</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-4">
                            <div class="menu-item">
                                <ul class="footer-link d-flex justify-content-sm-end justify-content-center align-items-center">
                                    <li><a href="terms-conditions.html">Terms</a></li>
                                    <li><a href="privacy-policy.html">Privacy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer Area End -->

  </div>

</template>

<style scoped>
.header-section {
    margin-left: 0px !important;
    width: 100% !important;
    border-bottom: none;
    box-shadow: 0px 1px 4px grey;
}
.header-section .navbar {
    padding: 8px 15px;
}

.title{
  font-size: 30px;
  margin-bottom: 1px;
}
.xlr {
  font-size: 20px;
}

.pb-120{
  padding-bottom: 40px;
}
</style>

<script setup>

import '../../../public/assets/css/bootstrap.min.css'
import '../../../public/assets/css/fontawesome.min.css'
import '../../../public/assets/css/jquery-ui.css'
// import '../../public/assets/css/nice-select.css'
import '../../../public/assets/css/magnific-popup.css'
import '../../../public/assets/css/slick.css'
import '../../../public/assets/css/arafat-font.css'
import '../../../public/assets/css/animate.css'
import '../../../public/assets/blog/css/styles.css'

import '../../../public/assets/blog/js/blog_main.js'

</script>

<script>
import blog from "@/services/blog.js";
export default {
  name: "HomePage",
  data() {
    return {
      posts: [],
      activeCategory: {},
      categories: {},
      search: "",
      page: 1,
      dataPerPage: 20,
      dataCount: 0,
      featuredPost: {},
    }
  },
  watch: {

  },
  components: {

  },
  mounted() {
    this.logOut()
    this.getPosts()
    this.getCategories()
  },
  created() {

  },
  methods: {
    logOut(){
      this.$store.dispatch('Auth/logout')
      this.$store.dispatch('User/logout')
    },
    scrollToSection(sectionId){
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    async getPosts(){
        try{
          this.posts = []
          this.featuredPost = {}
          let response = await blog.loadPosts(1, 100, this.search)
          this.posts = response.data['results'].slice(1)
          this.dataCount = response.data['count']

          if (this.dataCount > 0){
            this.featuredPost = response.data['results'][0]
          }
        }catch(err){
          console.log(err)
        }
    },
    async getCategories(){
        try{
          let response = await blog.loadCategories(1, 100, this.search)
          this.categories = response.data['results']
          // this.dataCount = response.data['count']
        }catch(err){
          console.log(err)
        }
    }
  },

}
</script>