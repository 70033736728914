import api from '../api/api'


const core_function = {
    loadClients(page, dataPerPage, search) {
        return new Promise((resolve, reject) => {
          api.get(`/clients/?page=${page}&page_size=${dataPerPage}&query=${search}`, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    postClient(content) {
        return new Promise((resolve, reject) => {
          api.post('/clients/', content, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    loadTrades(page, dataPerPage, search){
        return new Promise((resolve, reject) => {
          api.get(`/trades/?page=${page}&page_size=${dataPerPage}&query=${search}`, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    postTrade(content) {
        return new Promise((resolve, reject) => {
          api.post('/trades/', content, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    generateTradeSummaryReport(){
        return new Promise((resolve, reject) => {
          api.get('/trades/summary/report/', (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    loadCurrencies(page, dataPerPage, search){
        return new Promise((resolve, reject) => {
          api.get(`/currencies/?page=${page}&page_size=${dataPerPage}&query=${search}`, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    dashboardData(){
        return new Promise((resolve, reject) => {
          api.get('/dashboard/data/', (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    postCurrency(content) {
        return new Promise((resolve, reject) => {
          api.post('/currencies/', content, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    getCurrency(id){
        return new Promise((resolve, reject) => {
          api.get(`/currencies/${id}/`, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    updateCurrency(id, content) {
        return new Promise((resolve, reject) => {
          api.put(`/currencies/${id}/`, content, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
}

export default core_function;