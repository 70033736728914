<template>
  <!-- header-section start -->
    <header class="header-section body-collapse">
        <div class="overlay">
            <div class="container-fruid">
                <div class="row d-flex header-area">
                    <div class="navbar-area d-flex align-items-center justify-content-between">
                        <div class="sidebar-icon">
                            <img src="../../../public/assets/dashboard/images/icon/menu.png" alt="icon">
                        </div>
<!--                        <form action="#" class="flex-fill">-->
<!--                            <div class="form-group d-flex align-items-center">-->
<!--                                <img src="../../../public/assets/dashboard/images/icon/search.png" alt="icon">-->
<!--                                <input type="text" placeholder="Type to search...">-->
<!--                            </div>-->
<!--                        </form>-->
                        <div class="dashboard-nav">
                            <div class="single-item language-area">
                                <div class="language-btn">
                                    <img src="../../../public/assets/dashboard/images/icon/lang.png" alt="icon">
                                </div>
                                <ul class="main-area language-content">
                                    <li>English</li>
                                    <li>Hindi</li>
                                    <li class="active">English (US)</li>
                                    <li>Japanese</li>
                                    <li>Kannada</li>
                                    <li>Lithuanian</li>
                                </ul>
                            </div>
                            <div class="single-item notifications-area">
                                <div class="notifications-btn">
                                    <img src="../../../public/assets/dashboard/images/icon/bell.png" class="bell-icon" alt="icon">
                                </div>
                                <div class="main-area notifications-content">
                                    <div class="head-area d-flex justify-content-between">
                                        <h5>Notifications</h5>
                                        <span class="mdr">4</span>
                                    </div>
                                    <ul>
                                        <li>
                                            <a href="javascript:void(0)" class="d-flex">
                                                <div class="img-area">
                                                    <img src="assets/images/user-1.png" class="max-un" alt="image">
                                                </div>
                                                <div class="text-area">
                                                    <p class="mdr">You received a payment of <b>$134.00</b> from <b>Anna
                                                            Green</b></p>
                                                    <p class="mdr time-area">09.39AM</p>
                                                </div>
                                            </a>
                                            <i class="fa-solid fa-caret-right"></i>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" class="d-flex">
                                                <div class="img-area">
                                                    <img src="assets/images/user-2.png" class="max-un" alt="image">
                                                </div>
                                                <div class="text-area">
                                                    <p class="mdr"><b>James Deed</b> requested a payment of
                                                        <b>£890.00</b>
                                                    </p>
                                                    <p class="mdr time-area">08.09AM</p>
                                                </div>
                                            </a>
                                            <i class="fa-solid fa-caret-right"></i>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" class="d-flex">
                                                <div class="img-area">
                                                    <img src="assets/images/master-card.png" class="max-un" alt="image">
                                                </div>
                                                <div class="text-area">
                                                    <p class="mdr">Your new payment method has beed added successfully
                                                    </p>
                                                    <p class="mdr time-area">09.39AM</p>
                                                </div>
                                            </a>
                                            <i class="fa-solid fa-caret-right"></i>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" class="d-flex">
                                                <div class="img-area">
                                                    <img src="assets/images/user-3.png" class="max-un" alt="image">
                                                </div>
                                                <div class="text-area">
                                                    <p class="mdr">You received a payment of <b>$250.00</b> from Goerge
                                                        Michael</p>
                                                    <p class="mdr time-area">Yesterday</p>
                                                </div>
                                            </a>
                                            <i class="fa-solid fa-caret-right"></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="single-item user-area">
                                <div class="profile-area d-flex align-items-center">
                                    <span class="user-profile">
                                        <img src="assets/images/avatar.png" alt="User">
                                    </span>
                                    <i class="fa-solid fa-sort-down"></i>
                                </div>
                                <div class="main-area user-content">
                                    <div class="head-area d-flex align-items-center">
                                        <div class="profile-img">
                                            <img src="assets/images/avatar-2.png" alt="User">
                                        </div>
                                        <div class="profile-head">
                                            <a href="javascript:void(0)">
                                                <h5>Kevin Martin</h5>
                                            </a>
                                            <p class="wallet-id">Wallet ID: 6264849965</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li class="border-area">
                                            <a href="account.html"><i class="fas fa-cog"></i>Settings</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)"><i class="fas fa-sign-out"></i>Logout</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                  <div class="sidebar-wrapper">
                        <div class="close-btn">
                            <i class="fa-solid fa-xmark"></i>
                        </div>
                        <div class="sidebar-logo">
                            <a href="dashboard.html"><img src="../../../public/assets/images/logo.png" style="max-height: 50px" alt="logo"></a>
                        </div>
                        <ul>
                            <li class="active">
                                <router-link :to="'/admin/dashboard'">
                                    <img src="../../../public/assets/dashboard/images/icon/dashboard.png" alt="Dashboard"> <span>Dashboard</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="'/admin/clients'">
                                    <img src="../../../public/assets/dashboard/images/icon/transactions.png" alt="Transactions"> <span>Clients</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="'/admin/trades'">
                                    <img src="../../../public/assets/dashboard/images/icon/pay.png" alt="Pay"> <span>Trades</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="'/admin/currencies'">
                                    <img src="../../../public/assets/dashboard/images/icon/receive.png" alt="Receive"> <span>Currencies</span>
                                </router-link>
                            </li>

                            <li>
                                <router-link :to="'/admin/reports'">
                                  <i class="fa fa-book-medical" style="margin-right: 10px;"></i> <span> Reports</span>
                                </router-link>
                            </li>

                        </ul>
                        <ul class="bottom-item">
                            <li>
                                <a href="account.html">
                                    <img src="../../../public/assets/dashboard/images/icon/account.png" alt="Account"> <span>Log Out</span>
                                </a>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </header>
</template>


<script>

import currencies from "@/views/admin/Currencies.vue";

export default {
  computed: {
    currencies() {
      return currencies
    }
  },
  data() {
    return {

    };
  },
  methods: {

  },
  components: {

  },
};
</script>
<style>
.header-section.body-collapse {
    margin-left: 270px;
    width: calc(100% - 270px);
    border-bottom: none;
}
</style>
