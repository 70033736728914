<template>
  <div>
<!--    <sidebar />-->

    <!-- Dashboard Section start -->
    <section class="dashboard-section body-collapse transactions">
        <div class="overlay pt-120">
            <div class="container-fruid">
                <div class="head-area">
                    <div class="row">
                        <div class="col-lg-5 col-md-4">
                            <h4>Currencies</h4>
                        </div>
                        <div class="col-lg-7 col-md-8">
                            <div class="transactions-right d-flex align-items-center">
                                <form action="#" class="flex-fill">
                                    <div class="form-group d-flex align-items-center">
                                        <img src="../../../public/assets/dashboard/images/icon/search.png" alt="icon">
                                        <input type="text" placeholder="Type to search...">
                                    </div>
                                </form>
<!--                                <a href="javascript:void(0)">Monthly Statement</a>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12">
                        <div class="transactions-main">
                            <div class="top-items">
                                <h6>All Currencies</h6>
                                <div class="export-area">
                                    <ul class="d-flex align-items-center">
<!--                                        <li><a href="javascript:void(0)"><img src="../../../public/assets/dashboard/images/icon/printer.png" alt="icon">Print</a></li>-->
<!--                                        <li><a href="javascript:void(0)"><img src="../../../public/assets/dashboard/images/icon/excel.png" alt="icon">Excel</a></li>-->
<!--                                        <li><a href="javascript:void(0)"><img src="../../../public/assets/dashboard/images/icon/pdf.png" alt="icon">PDF</a></li>-->
                                        <li>
                                          <a class="btn btn-success" @click="$router.push('/admin/new/currency')">
                                            New Currency
                                          </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                      <tr>
                                        <th style="text-align: left; width: 15%">Name</th>
                                        <th style="text-align: center">Initials</th>
                                        <th style="text-align: left">Conversion Factor</th>
                                        <th style="text-align: left">Last Updated</th>
                                        <th style="text-align: left">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="currency in currencies" v-bind:key="currency.id">
                                        <td style="text-align: left; width: 15%">{{ currency.name }}</td>
                                        <td style="text-align: center">{{ currency.initials }}</td>
                                        <td style="text-align: right">{{ currency.conversion_factor }}</td>
                                        <td style="text-align: left">{{ currency.last_updated }}</td>
                                        <td style="text-align: left">
                                          <router-link :to="'/admin/edit/currency/'+currency.id" class="btn"><i class="fa fa-edit"></i> Edit</router-link>
                                        </td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>

                            <nav aria-label="Page navigation" class="d-flex justify-content-center mt-40">
                              <pagination :records="dataCount" v-model="page" :per-page="dataPerPage" @paginate="getCurrencies()" :options="{'chunk': 4}">
                              </pagination>
                            </nav>
                            <!-- <nav aria-label="Page navigation" class="d-flex justify-content-center mt-40">
                                <ul class="pagination justify-content-center align-items-center mb-40">
                                    <li class="page-item">
                                        <a class="page-link previous" href="javascript:void(0)" aria-label="Previous">
                                            <i class="fa-solid fa-angles-left"></i>
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link previous" href="javascript:void(0)" aria-label="Previous">
                                            <i class="fa-solid fa-angle-left"></i>
                                        </a>
                                    </li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)">1</a></li>
                                    <li class="page-item"><a class="page-link active" href="javascript:void(0)">2</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)">...</a></li>
                                    <li class="page-item">
                                        <a class="page-link next" href="javascript:void(0)" aria-label="Next">
                                            <i class="fa-solid fa-angle-right"></i>
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link next" href="javascript:void(0)" aria-label="Next">
                                            <i class="fa-solid fa-angles-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Dashboard Section end -->
  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import HeaderStats from "@/components/Headers/HeaderStats.vue";
import FooterAdmin from "@/components/Footers/FooterAdmin.vue";
// import TableDropdown from "@/components/Dropdowns/TableDropdown.vue";
import core_function from "@/services/core_function";

export default {
  name: "admin-layout",
  data() {
    return {
      currencies: [],
      page: 1,
      dataCount: 0,
      search: "",
      dataPerPage: 10,
    };
  },
  components: {
    // TableDropdown,
    AdminNavbar,
    Sidebar,
    HeaderStats,
    FooterAdmin,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    async getCurrencies(){
      try{
        let response = await core_function.loadCurrencies(this.page, this.dataPerPage, this.search)
        this.currencies = response.data['results']
        this.dataCount = response.data['count']
      }catch (err){
        console.log(err)
      }
    }
  },
  mounted() {
    this.getCurrencies()
  }
};
</script>

<style scoped>
.pagination .page-item:hover, .pagination .page-item.active, .pagination .page-link:hover, .pagination .page-link.active {
    background-color: green;
    color: var(--bs-white);
}

th {
  background-color: #14d18f;
  padding: 4px 7px !important;
  color: black !important;
}

td {
  padding: 4px 7px !important;
}

table, th, td {
  border-top: 1px solid lightgray !important;
  border-bottom: 1px solid lightgray !important;
}
th {
  border-left: 1px solid lightgray !important;
}
table {
  border-left: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
}
</style>