<template>

  <!-- Dashboard Section start -->
    <section class="dashboard-section body-collapse transactions">
        <div class="overlay pt-120">
            <div class="container-fruid">
                <div class="head-area">
                    <div class="row">
                        <div class="col-lg-5 col-md-4">
                            <h4>Currencies</h4>
                        </div>
                        <div class="col-lg-7 col-md-8">
<!--                            <div class="transactions-right d-flex align-items-center">-->
<!--                                <form action="#" class="flex-fill">-->
<!--                                    <div class="form-group d-flex align-items-center">-->
<!--                                        <img src="../../../public/assets/dashboard/images/icon/search.png" alt="icon">-->
<!--                                        <input type="text" placeholder="Type to search...">-->
<!--                                    </div>-->
<!--                                </form>-->
<!--&lt;!&ndash;                                <a href="javascript:void(0)">Monthly Statement</a>&ndash;&gt;-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12">
                        <div class="transactions-main">
                            <div class="top-items">
                                <h6>Edit Currency</h6>
                                <div class="export-area">
                                    <ul class="d-flex align-items-center">

                                    </ul>
                                </div>
                            </div>

<!--                            <div class="table-responsive">-->
                              <form class="form" @submit.prevent="updateCurrency()">
                              <div class="row">
                                  <div class="col-sm-6 pl-2">
                                    <div class="form-group">
                                      <label class="form-label">Name</label>
                                      <input type="text"
                                             v-model="form.name" class="form-control">
                                    </div>

                                    <div class="form-group">
                                      <label class="form-label">Initials</label>
                                      <input type="text" v-model="form.initials" class="form-control">
                                    </div>

                                  </div>

                                  <div class="col-sm-6 pr-2">
                                    <div class="form-group">
                                      <label class="form-label">Conversion Factor</label>
                                      <input type="number" v-model="form.conversion_factor" class="form-control">
                                    </div>

                                    <br>
                                    <div class="form-group">
                                      <button class="btn btn-success" type="submit">Submit</button>
                                    </div>
                                  </div>
                              </div>
                                </form>
<!--                            </div>-->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Dashboard Section end -->

</template>
<script>

import core_function from "@/services/core_function";

export default {
  data(){
    return{
      form: {},
    }
  },
  components: {

  },
  methods: {
    async updateCurrency(){
      try{
        let response = await core_function.updateCurrency(this.$route.params.id, this.form)
        if(response.status === 200){
          this.$router.push('/admin/currencies')
        }else{
          console.log(response)
        }
      }catch (err){
        console.log(err)
      }
    },
    async getCurrency(){
      try{
        let response = await core_function.getCurrency(this.$route.params.id)
        this.form = response.data
      }catch (err){
        console.log(err)
      }
    }
  },
  mounted() {
    this.getCurrency()
  },
  computed: {

  },
};
</script>
